import React from 'react';
import { useSearch } from '../../../../SearchContext';
import { Fields } from '../../styles';
import { nanoid } from 'nanoid';
import Filters from '~/components/Filters';

export const Filtros: React.FC = () => {
  const {
    setOpenFields,
    isOpenFields,
    handleOpenFiltro,
    resetBusca,
    listaDeFiltrosOrdenados,
    searchOnClick,
    removeFilter,
    handleAddFilter,
    idFiltro,
    filtersDaTela,
    setIdFiltro,
  } = useSearch();

  return (
    <>
      <Fields
        style={{
          display: isOpenFields ? 'block' : 'none',
          zIndex: 10 + (filtersDaTela.length + 1),
        }}
        onMouseLeave={() => setOpenFields(false)}
      >
        <>
          {listaDeFiltrosOrdenados.length > 0 ? (
            <ul>
              {listaDeFiltrosOrdenados.map((listaOrdenadaAlfabetica: any) => {
                return (
                  <div
                    key={nanoid()}
                    className="container-listaDeFiltrosOrdenados"
                  >
                    {listaOrdenadaAlfabetica.map(
                      (dadosDoCampo: any) =>
                        dadosDoCampo.flg_filtro && (
                          <li
                            className="listaDeFiltrosOrdenados"
                            key={dadosDoCampo.cod_campo}
                          >
                            <button
                              type="button"
                              title={`${dadosDoCampo.des_campo}${
                                dadosDoCampo.des_sinonimo
                                  ? `(${dadosDoCampo.des_sinonimo})`
                                  : ''
                              }`}
                              onClick={() => {
                                handleOpenFiltro(dadosDoCampo);
                                setOpenFields(false);
                                resetBusca();
                              }}
                            >
                              {dadosDoCampo.des_campo}
                              <small>
                                {dadosDoCampo.des_sinonimo
                                  ? ` ${dadosDoCampo.des_sinonimo.replaceAll(
                                      ',',
                                      ', ',
                                    )}`
                                  : ''}
                              </small>
                            </button>
                          </li>
                        ),
                    )}
                  </div>
                );
              })}
            </ul>
          ) : (
            <div style={{ margin: 'auto', width: '260px' }}>
              Nenhum campo encontrado para pesquisa
            </div>
          )}
        </>
      </Fields>
      <ul id="ClickedContainerFilter" className="filters">
        {filtersDaTela.length > 0 && (
          <>
            {filtersDaTela
              .map((filter: any, index: number) => (
                <Filters
                  zindex={filtersDaTela.length - index}
                  handleSetValue={(val) => {
                    setIdFiltro('');
                    handleAddFilter(val);
                  }}
                  filter={filter}
                  key={filter.des_nome}
                  removeFilter={removeFilter}
                  isPopUp={filter.index === idFiltro}
                  searchOnClick={searchOnClick}
                  isFilterBlock={filter.isBlock}
                />
              ))
              .sort((a: any, b: any) => {
                if (a?.props?.isFilterBlock && !b?.props?.isFilterBlock) {
                  return -1;
                }
                if (!a?.props?.isFilterBlock && b?.props?.isFilterBlock) {
                  return 1;
                }
                return 0;
              })}
          </>
        )}
      </ul>
    </>
  );
};
