import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'container',
})`
  .MuiLinearProgress-colorPrimary {
    background-color: #fff !important;
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: #c2a0f8 !important;
  }
  .containerMaterial {
    min-width: fit-content;
    width: auto;
    padding: 0px 4px;
    order: 1;
    margin: 0px 64px 2px 0px;
  }
  .linkMaterial {
    font-size: 14px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ContainerButtonsHandler = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
`;

export const DisplayFlex = styled.div`
  display: flex;
  flex: 1;

  @media screen and (max-width: 1199px) {
    flex-wrap: wrap;
  }
`;

export const ContainerOrder = styled.div`
  margin-left: 30px;
  @media (min-width: 500px) {
    margin-right: 10px;
  }
`;

export const ButtonReturnCards = styled(motion.button)`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  min-width: 150px;
  background-color: #ffc107;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  animation: all 0.25s ease-in;
  &.addNew {
    background-color: #007bff;
    &.span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.ordenar {
    background-color: rgb(51, 185, 21);
    &.span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:disabled {
    background-color: #f1e1b0;
    cursor: not-allowed;
    &:hover {
      background-color: #f1e1b0;
      opacity: 1;
    }
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const SectionSearchContainer = styled.div`
  width: 100%;
  h2 {
    font-size: 18px;
    height: 25px;
    display: flex;
    padding: 5px;
    justify-content: right;
    align-content: top;

    svg {
      margin-left: 10px;
      margin-right: 5px;
    }
  }
  input.search {
    width: 100%;
    padding: 3px 20px;
    height: 41px;
    margin-right: 10px;
    border: 1px solid ${({ theme: { colors } }) => colors.primary};
    border-radius: 4px;

    &:focus {
      & ~ .fields {
        display: block;
      }
    }
  }
  position: relative;

  .filters {
    display: flex;
    padding: 10px 0px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
`;
export const Fields = styled.div`
  width: 100%;
  padding: 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);
  margin-top: 5px;
  position: absolute;
  background-color: ${({ theme: { colors } }) => colors.background};
  display: none;
  z-index: 5;
  border-radius: 15px;
  ul {
    display: table;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    table-layout: fixed;
    width: 100%;

    .container-listaDeFiltrosOrdenados {
      display: table-row;

      .listaDeFiltrosOrdenados {
        display: table-cell;
        padding: 10px 10px;

        button {
          background-color: transparent;
          border: none;
          color: ${({ theme: { colors } }) => colors.primary};
          text-align: left !important;

          small {
            font-size: 10px;
            color: ${({ theme: { colors } }) => colors.secondary};
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    ul {
      display: flex !important;
      flex-direction: column !important;
      li {
        display: flex !important;
        flex-direction: column !important;
        .listaDeFiltrosOrdenados {
          display: inline-block !important;
        }
      }
    }
  }
`;
export const SearchBarContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 5;

  .btns {
    position: absolute;
    right: 0;
    top: 0px;
    display: flex;
    align-items: center;

    @media (max-width: 700px) {
      position: relative;
      margin-top: 10px;
    }

    button {
      height: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #57069e;
      margin-right: 1px;
      border-radius: 0px;
      color: #ffffff !important;

      &:hover {
        opacity: 0.85;
      }

      &:nth-child(3n) {
        border-left: 0;
      }

      &:nth-child(2n) {
        border-left: 0;
      }

      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }

      @media (max-width: 700px) {
        &:first-child {
          border-radius: 4px 0px 0px 4px;
        }
      }

      svg {
        font-size: 17.49px;
      }
    }
  }
`;
